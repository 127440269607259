<template>
  <div>
    <form @submit.prevent="onSubmit">
      <vx-card>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/2 w-full">

            <vs-input
              class="w-full"
              label="Quotation Number"
              v-model="form.nomor"
              val-icon-danger="icon-x"
              val-icon-pack="feather"
              :danger="error.nomor ? true : false"
              :danger-text="error.nomor ? error.nomor.join(', ') : ''"
            />

          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="vs-input--label">{{$i18n.t('Date')}}</label>
            <datepicker format="d/MM/yyyy" :value="form.tanggal" @input="form.tanggal = $moment($event)"></datepicker>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input
              class="w-full"
              type="text"
              label="Sales"
              v-model="form.sales"
              val-icon-danger="icon-x"
              val-icon-pack="feather"
              :danger="error.sales ? true : false"
              :danger-text="error.sales ? error.sales.join(', ') : ''"
            />
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input
              class="w-full"
              type="text"
              label="Customer"
              v-model="form.customer"
              val-icon-danger="icon-x"
              val-icon-pack="feather"
              :danger="error.customer ? true : false"
              :danger-text="error.customer ? error.customer.join(', ') : ''"
            />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/1 w-full">
            <label for="" class="vs-input--label">{{$i18n.t('Description')}}</label>
            <vs-textarea
              height="200px"
              class="w-full"
              v-model="form.deskripsi"
            />
            <span class="text-danger text-sm" v-show="error.deskripsi ? true : false">{{ error.deskripsi ? error.deskripsi.join(', ') : '' }}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full">

            <div class="flex">
              <vs-button class="mr-3 mb-2" icon-pack="feather" icon="icon-save" @click.prevent="onSubmit">{{ $i18n.t('Save') }}</vs-button>
              <vs-button type="flat" color="primary" class="mb-2" @click.prevent="$router.push({ name: 'quotation' })">{{ $i18n.t('Cancel') }}</vs-button>
            </div>

          </div>
        </div>

      </vx-card>
    </form>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker'

export default {
  name: "QuotationForm",
  data: () => ({
    form: {},
    error: {}
  }),
  components: {
    Datepicker
  },
  methods: {
    onSubmit () {
      let type = 'quotation/storeItem', payload = { body: this.form };
      if (this.id) {
        type = 'quotation/editItem';
        payload.body._method = 'PUT';
        payload.id = this.id;
      }

      this.$store.dispatch(type, payload).then((response) => {
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          this.$router.push({name: 'quotation'});
        });
      }).catch(err => {

        // set error
        if (err.data.errors) {
          this.error = err.data.errors;
        }

        // show message error
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })

      });
    }
  },
  computed: {
    ...mapGetters({
      'item': 'quotation/showItem'
    })
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$secure.decrypt(this.$route.params.id);
      this.$store.dispatch('quotation/detailItem', { id: this.id }).then(() => {
        this.form = this.item;
      });
    }
  }
}
</script>

<style scoped>

</style>
